import { createRouter, createWebHistory } from 'vue-router'
import { ElMessage } from 'element-plus'
import getTitle from '@/utils/getTitle'
import Layout from '@/layout'
import store from '../store'

/**
 * 路由相关属性说明
 * hidden: 当设置hidden为true时，意思不在sideBars侧边栏中显示
 * mete{
 * title: xxx,  设置sideBars侧边栏名称
 * icon: xxx,  设置ideBars侧边栏图标
 * noCache: true  当设置为true时不缓存该路由页面
 * require Auth: true  当设置为true时,需要登录的路由页面必须要有一个登录的权限
 * }
 */
/*通用routers*/
export const currencyRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/LoginPage.vue'),
    meta: { title: '登录页', requiresAuth: false },
    hidden: true,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/views/signup'),
    meta: { title: '注册页', requiresAuth: false },
    hidden: true,
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/views/mainPage/OurServices.vue'),
    meta: { title: '服务', requiresAuth: false },
    hidden: true,
  },
  {
    path: '/about',
    name: 'About Us',
    component: () => import('@/views/mainPage/AboutUs.vue'),
    meta: { title: '关于我们', requiresAuth: false },
    hidden: true,
  },
  {
    path: '/',
    name: 'Main',
    component: () => import('@/views/mainPage/index.vue'),
    meta: { title: '主页', requiresAuth: false },
    hidden: true,
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import('@/views/mainPage/ContactUs.vue'),
    meta: { title: '联系我们', requiresAuth: false },
    hidden: true,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error-page/404.vue'),
    hidden: true,
    meta: { title: '404', requiresAuth: false },
  },
  {
    path: '/tracking',
    name: 'tracking',
    redirect: '/404',
    hidden: true,
  },
  {
    path: '/tracking/:trackingID',
    name: 'tracking_status',
    component: () => import('@/views/mainPage/tracking/RealTracking.vue'),
    hidden: true,
    meta: { title: '追踪包裹', requiresAuth: false },
  },
  {
    path: '/home',
    name: 'Home',
    component: Layout,
    redirect: '/home/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard'),
        meta: { title: '首页', icon: 'DataAnalysis' },
      },
    ],
  },

  {
    path: '/account',
    name: 'Account',
    component: Layout,
    redirect: '/Account/index',
    hidden: true,
    children: [
      {
        path: 'index',
        name: 'Account-index',
        component: () => import('@/views/account/index.vue'),
        meta: { title: '账户中心', requiresAuth: true },
      },
    ],
  },
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    component: () => import('@/views/forget-password'),
    hidden: true,
    meta: { title: '忘记密码', requiresAuth: false, noCache: true },
  },
  {
    path: '/resetPassword/',
    name: 'ResetPassword',
    component: () => import('@/views/forget-password/reset-password'),
    hidden: true,
    meta: { title: '重置密码', requiresAuth: false, noCache: true },
  },
  {
    path: '/resetPassword/:token',
    name: 'ResetPassword-verify',
    component: () => import('@/views/forget-password/verify-page'),
    hidden: true,
    meta: { title: '重置密码', requiresAuth: false, noCache: true },
  },
  {
    path: '/driver',
    name: 'Driver',
    component: Layout,
    redirect: '/driver/index',
    hidden: true,
    children: [
      {
        path: 'index',
        name: 'Driver-index',
        component: () => import('@/views/driver-page'),
        meta: {
          title: '引导指南',
          requiresAuth: false,
          icon: 'Flag',
        },
      },
    ],
  },
  // {
  //   path: '/confirmation',
  //   name: 'Confirmation',
  //   component: () => import('@/views/data-operate/confirmation'),
  //   meta: { title: '回执单', requiresAuth: false },
  //   hidden: true,
  // },
  {
    path: '/testlabel',
    name: 'testlabel',
    component: () => import('@/InternalView/print_label'),
    meta: { title: '打印标签', requiresAuth: false },
    hidden: true,
  },
]
/*动态添加routers*/
export const asyncRoutes = [
  {
    path: '/allorders',
    component: Layout,
    name: 'AllOrders',
    redirect: '/allorders/order',
    meta: {
      icon: 'Document',
      title: '包裹详情',
      requiresAuth: true,
      noCache: false,
    },
    children: [
      {
        path: 'order',
        name: 'Order',
        component: () => import('@/views/data-operate/allpackages'),
        meta: { title: '包裹查询', requiresAuth: true, icon: 'Document', noCache: false },
      },
    ],
  },
  // {
  //   path: '/generate',
  //   name: 'Generate',
  //   component: Layout,
  //   redirect: '/generate/models',
  //   meta: { title: '生成模版', requiresAuth: true, icon: 'Tickets', noCache: false },
  //   children: [
  //     {
  //       path: 'models',
  //       name: 'Models',
  //       component: () => import('@/InternalView/generate'),
  //       meta: { title: '选小票模版', icon: 'Tickets' },
  //     },
  //     {
  //       path: 'beauty',
  //       name: 'Beauty',
  //       hidden: true,
  //       component: () => import('@/InternalView/generate/models/beauty.vue'),
  //       meta: { title: 'Beauty', icon: 'Tickets' },
  //     },
  //     {
  //       path: 'fashion',
  //       name: 'Fashion',
  //       hidden: true,
  //       component: () => import('@/InternalView/generate/models/fashion.vue'),
  //       meta: { title: 'Fashion', icon: 'Tickets' },
  //     },
  //     {
  //       path: 'theory',
  //       name: 'Theory',
  //       hidden: true,
  //       component: () => import('@/InternalView/generate/models/theory.vue'),
  //       meta: { title: 'Theory', icon: 'Tickets' },
  //     },
  //     {
  //       path: 'coffee',
  //       name: 'Coffee',
  //       hidden: true,
  //       component: () => import('@/InternalView/generate/models/coffee.vue'),
  //       meta: { title: 'Coffee', icon: 'Tickets' },
  //     },
  //     {
  //       path: 'liquor',
  //       name: 'Liquor',
  //       hidden: true,
  //       component: () => import('@/InternalView/generate/models/liquor.vue'),
  //       meta: { title: 'Liquor', icon: 'Tickets' },
  //     },
  //     {
  //       path: 'others',
  //       name: 'Others',
  //       hidden: true,
  //       component: () => import('@/InternalView/generate/models/others.vue'),
  //       meta: { title: 'Others', icon: 'Tickets' },
  //     },
  //   ],
  // },
  {
    path: '/driver',
    name: 'Driver',
    component: Layout,
    redirect: '/driver/pickup',
    meta: { title: '取货操作', requiresAuth: true, icon: 'Memo', noCache: false },
    children: [
      {
        path: 'pickup',
        name: 'Pickup',
        component: () => import('@/views/driver-pickup/pickup'),
        meta: { title: '取货', requiresAuth: true },
      },
      {
        path: 'pickup-history',
        name: 'Pickup-history',
        component: () => import('@/views/driver-pickup/pickup-history'),
        meta: { title: '取货记录', requiresAuth: true },
      },
    ],
  },
  {
    path: '/Finance',
    component: Layout,
    name: 'Finance',
    hidden: true,
    meta: {
      icon: 'Memo',
      title: '订单处理',
    },
    children: [
      {
        path: 'Invoice/:invoiceNum',
        name: 'Invoice',
        component: () => import('@/InternalView/Finance/CreateInvoice'),
        meta: { title: '发票', requiresAuth: true },
        hidden: true,
      },
    ],
  },
  {
    path: '/Orders',
    component: Layout,
    name: 'Orders',
    meta: {
      icon: 'Memo',
      title: '订单处理',
    },
    children: [
      {
        path: 'OrderDetail/:ordernum',
        name: 'OrderDetail',
        hidden: true,
        component: () => import('@/views/data-operate/orderdetail'),
        meta: { title: '订单详情', requiresAuth: true, noCache: true },
      },
      {
        path: 'bulkorders',
        name: 'bulkOrders',
        hidden: false,
        component: () => import('@/views/data-operate/bulk-upload'),
        meta: { title: '批量上传', requiresAuth: true, noCache: true },
      },
      {
        path: 'retailupload',
        name: 'RetailUpload',
        hidden: true,
        component: () => import('@/views/data-operate/retailupload'),
        meta: {
          title: '包裹基础信息',
          requiresAuth: true,
        },
      },
      {
        path: 'createshipment',
        name: 'CreateShipment',
        component: () => import('@/views/data-operate/createshipment'),
        meta: {
          title: '创建包裹',
          requiresAuth: true,
        },
      },
      {
        path: 'createlocalshipment',
        name: 'CreateLocalShipment',
        component: () => import('@/views/data-operate/createlocalshipment'),
        meta: {
          title: '创建本地包裹',
          requiresAuth: true,
        },
      },
      {
        path: 'trackshipment',
        name: 'TrackShipment',
        component: () => import('@/views/data-operate/trackshipment'),
        meta: {
          title: '追踪包裹',
          requiresAuth: true,
        },
      },
      {
        path: 'uploadID',
        name: 'UploadID',
        component: () => import('@/views/data-operate/upload_id'),
        meta: {
          title: '更新身份证',
          requiresAuth: true,
          noCache: true,
        },
      },
      {
        path: 'uploadphotoid',
        name: 'UploadphotoID',
        component: () => import('@/views/data-operate/upload_id_photo'),
        meta: {
          title: '上传身份证照片',
          requiresAuth: true,
          noCache: true,
        },
      },
      {
        path: 'reprintlabel/:tracking_num',
        name: 'ReprintLabel',
        hidden: true,
        component: () => import('@/views/data-operate/confirmation/RePrint'),
        meta: {
          title: '重印标签',
          requiresAuth: true,
          noCache: true,
        },
      },
      {
        path: 'hklabel/:tracking_num',
        name: 'hklabel',
        hidden: true,
        component: () => import('@/views/data-operate/confirmation/hklabel'),
        meta: {
          title: '确认包裹',
          requiresAuth: true,
          noCache: true,
        },
      },
    ],
  },
  {
    path: '/Inbound',
    component: Layout,
    name: 'Inbound',
    meta: {
      icon: 'HomeFilled',
      title: '入库',
    },
    children: [
      {
        path: 'InReceiving',
        name: 'InReceiving',
        component: () => import('@/InternalView/Inbound/Receiving'),
        meta: { title: '入库扫描', requiresAuth: true, icon: 'HomeFilled', noCache: true },
      },
      {
        path: 'cnReceiving',
        name: 'cnReceiving',
        component: () => import('@/cn_internal/cn_inbound/inbound.vue'),
        meta: { title: '入库扫描', requiresAuth: true, icon: 'HomeFilled', noCache: true },
      },
      {
        path: 'hkReceiving',
        name: 'hkReceiving',
        component: () => import('@/hk_internal/hk_inbound/inbound.vue'),
        meta: { title: '入库扫描', requiresAuth: true, icon: 'HomeFilled', noCache: true },
      },
      {
        path: 'Packing',
        name: 'Packing',
        component: () => import('@/InternalView/Inbound/Packing'),
        meta: { title: '普货打包(GZ)', requiresAuth: true,icon: 'HomeFilled', noCache: true },
      },
      {
        path: 'FZ-Packing',
        name: 'FZ-Packing',
        component: () => import('@/InternalView/Inbound/Packing-FZ'),
        meta: { title: '普货打包(FZ)', requiresAuth: true,icon: 'HomeFilled', noCache: true },
      },
      {
        path: 'HK-Packing',
        name: 'HK-Packing',
        component: () => import('@/InternalView/Inbound/HK-Packing'),
        meta: { title: '特快线打包', requiresAuth: true, icon: 'HomeFilled', noCache: true },
      },
      {
        path: 'packed',
        name: 'packed',
        component: () => import('@/InternalView/Inbound/Packed'),
        meta: { title: '已打包', requiresAuth: true, icon: 'HomeFilled',noCache: true },
      },
    ],
  },
  {
    path: '/Cn_Label',
    name: 'Cn_Label',
    hidden: true,
    meta: {
      icon: 'Memo',
      title: '中国面单',
    },
    children: [
      {
        path: 'FZ-label/:tracking_num',
        name: 'FZ-label',
        component: () => import('@/InternalView/Inbound/model/print_fz_label'),
        hidden: true,
        meta: { title: '邮政面单(FZ)', requiresAuth: true,icon: 'Postcard', noCache: true },
      },
    ],
  },
  {
    path: '/UpdateSF',
    component: Layout,
    name: 'UpdateSF',
    meta: {
      icon: 'Switch',
      title: '更新国内快递单号',
    },
    children: [
      {
        path: 'SF_update',
        name: 'SF_update',
        component: () => import('@/cn_internal/cn_update_SF/SF_update'),
        meta: { title: 'SF更新', requiresAuth: true, icon: 'Switch', noCache: true },
      },
      
    ],
  },
  {
    path: '/Outbound',
    component: Layout,
    name: 'Outbound',
    meta: {
      icon: 'Promotion',
      title: '出库',
    },
    children: [
      {
        path: 'cnOutbound',
        name: 'cnOutbound',
        component: () => import('@/cn_internal/cn_outbound/outbound'),
        meta: { title: '出库扫描', requiresAuth: true, icon: 'Promotion', noCache: true },
      },
      {
        path: 'hkOutbound',
        name: 'hkOutbound',
        component: () => import('@/hk_internal/hk_outbound/outbound'),
        meta: { title: '出库扫描', requiresAuth: true, icon: 'Promotion', noCache: true },
      },
      {
        path: 'IntoBatch',
        name: 'IntoBatch',
        component: () => import('@/InternalView/Outbound/IntoBatch'),
        meta: { title: '普货出库', requiresAuth: true, icon: 'Promotion', noCache: true },
      },
      {
        path: 'HK-IntoBatch',
        name: 'HK-IntoBatch',
        component: () => import('@/InternalView/Outbound/HK-intoBatch'),
        meta: { title: '特快线出库', requiresAuth: true, icon: 'Promotion', noCache: true },
      },
      // {
      //   path: 'ComfirmBatch',
      //   name: 'ComfirmBatch',
      //   component: () => import('@/InternalView/Outbound/ComfirmBatch'),
      //   meta: { title: '打板信息', requiresAuth: true, noCache: true },
      // },
      // {
      //   path: 'HistoryPackages',
      //   name: 'HistoryPackages',
      //   component: () => import('@/InternalView/Outbound/HistoryPackages'),
      //   meta: { title: '历史记录', requiresAuth: true, noCache: true },
      // },
    ],
  },
  {
    path: '/cnHistory',
    component: Layout,
    name: 'cnHistory',
    meta: {
      icon: 'Histogram',
      title: '历史记录',
    },
    children: [
      {
        path: 'CN_Record',
        name: 'CN_Record',
        component: () => import('@/cn_internal/cn_record/record'),
        meta: { title: '历史查询', requiresAuth: true, icon: 'Histogram', noCache: true },
      },
      {
        path: 'HK_Record',
        name: 'HK_Record',
        component: () => import('@/hk_internal/hk_record/record'),
        meta: { title: '历史查询', requiresAuth: true, icon: 'Histogram', noCache: true },
      },
    ]
  },
  {
    path: '/announcement',
    component: Layout,
    name: 'Announcement',
    meta: {
      icon: 'BellFilled',
      title: '公告',
    },
    children: [
      {
        path: 'announcement-publish',
        name: 'Announcement-publish',
        component: () => import('@/views/announcement'),
        meta: { title: '发布公告', icon: 'BellFilled', requiresAuth: true },
      },
    ],
  },
  {
    path: '/errorlog',
    component: Layout,
    name: 'ErrorLog',
    meta: {
      icon: 'WarnTriangleFilled',
      title: '公告',
    },
    children: [
      {
        path: 'notice',
        name: 'Notice',
        component: () => import('@/InternalView/errorlog'),
        meta: { title: '包裹错误日志', icon: 'WarnTriangleFilled', requiresAuth: true },
      },
    ],
  },
  {
    path: '/History',
    component: Layout,
    name: 'History',
    hidden: true,
    meta: {
      icon: 'Memo',
      title: '历史记录',
    },
    children: [
      {
        path: 'Receiving',
        name: 'Receiving',
        hidden: true,
        component: () => import('@/views/History/receiving_history'),
        meta: { title: '入库包裹详情', requiresAuth: true, noCache: true },
      }
    ]
  }
  // {
  //   path: '*',
  //   hidden: true,
  //   redirect: '/404'
  // }
]
const router = createRouter({
  history: createWebHistory(),
  routes: currencyRoutes,
  scrollBehavior() {
    return { left: 0, top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  document.title = getTitle(to.meta.title);

  // 添加这个条件来检查是否需要身份验证
  const requiresAuth = to.meta.requiresAuth;

  if (requiresAuth === false) {
    // 如果页面不需要身份验证，则直接进入
    next();
    return;
  }

  if (to.path === '/login') {
    next();
  } else {
    if (store.getters.token) {
      const hasRoles = store.getters.roles.length > 0;
      if (hasRoles) {
        next();
      } else {
        try {
          const { roles } = await store.dispatch('user/_getInfo');
          const addRoutes = await store.dispatch('permission/getAsyncRoutes', roles);

          for (const route of addRoutes) {
            router.addRoute(route);
          }

          next({ ...to, replace: true });
        } catch (error) {
          ElMessage.error(error);
        }
      }
    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      });
    }
  }
});
export default router
